import { useEffect, useState } from 'react';
import './App.css';
import ReactBarcode from 'react-jsbarcode';


//75

const App = () => {
  const [barcodes, setBarcodes] = useState([]);
  const [visibility, setVisibility] = useState(true);
  const handleChange = (e) => {
    if (e.target.value) {
      let barcodes = e.target.value.split(/\r?\n/);
      let barcodes_arr = [];
      for (let barcode of barcodes) {
        if (barcode && barcode.length == 13) {
          barcodes_arr.push({ value: barcode, type: "EAN13" })
        } else if (barcode && barcode.length == 8) {
          barcodes_arr.push({ value: barcode, type: "EAN8" })
        }
      }

      const chunkSize = 75;
      let chunkedArr = [];
      for (let i = 0; i < barcodes_arr.length; i += chunkSize) {
        const chunk = barcodes_arr.slice(i, i + chunkSize);
        chunkedArr.push(chunk);
        console.log(chunkedArr)
      }

      setBarcodes(chunkedArr)
    }
  }
  const handleConfirm = (e) => {
    setVisibility(false)
  }
  return (
    <>
      <textarea onChange={handleChange} className={visibility ? "" : "d-none"} />
      <button onClick={handleConfirm} className={visibility ? "" : "d-none"}>Generate Barcodes</button>

      {!visibility ? barcodes.map((barcodes_arr, arrindex) => (
        <div key={"arr" + arrindex} className="page-barcode">
          {barcodes_arr.map((barcode, index) => (
            <div className={"barcode-wrap"}>
              <div className="barcode_order">{arrindex * 75 + index + 1}</div>
              <ReactBarcode key={index} value={barcode.value} options={{ format: barcode.type, width: 1, height: 30 }} />
            </div>
          ))}
        </div>
      )) : ""}
    </>
  )
};

export default App;
